@import '../styles/colors.scss';
@import '../styles/muiOverrides/muiOverrides.module.scss';

.App {
    height: 100dvh;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: var(--white);
    color: var(--black);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12pt;
    overflow: hidden;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }

    html {
        scroll-behavior: smooth;
    }
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: var(--app-link);
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

html,
body {
    overflow-x: hidden;
    background-color: var(--grey-alt);
    /* Prevent scroll on narrow devices */
}

/* body {
    padding-top: 56px;
} */

@media (max-width: 991.98px) {
    .offcanvas-collapse {
        position: fixed;
        top: 56px;
        /* Height of navbar */
        bottom: 0;
        left: 100%;
        width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
        overflow-y: auto;
        visibility: hidden;
        background-color: var(--charcoal);
        transition: visibility 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    }
}

.nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
}

.nav-scroller .nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    color: rgba(var(--white), 0.75);
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.nav-underline .nav-link,
.nav-underline .nav-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.875rem;
    color: var(--grey-dark);
}

.nav-underline .nav-link:hover,
.nav-underline .nav-item:hover {
    color: var(--bright-blue);
}

.nav-underline .active {
    font-weight: 500;
    color: var(--charcoal);
}

.text-white-50 {
    color: rgba(var(--white), 0.5);
}

.bg-purple {
    background-color: var(--bright-purple);
}

.lh-100 {
    line-height: 1;
}

.lh-125 {
    line-height: 1.25;
}

.lh-150 {
    line-height: 1.5;
}

nav .navbar-collapse {
    position: absolute;
    right: -100%;
    /* pull the menu out of the viewport from right */
    display: block;
    transition: right 0.3s ease;
    /* transition for smooth sliding */
}

nav .navbar-collapse.show {
    right: 0;
    /* push the menu back to viewport */
}

nav .nav-link,
nav .nav-item {
    margin: 0px 6px;
    padding-left: 16px !important;
    border-radius: 4px;
    color: var(--black) !important;
    text-align: left;
    font-weight: 600;
}

nav .nav-link:hover,
nav .nav-item:hover {
    background-color: var(--grey-alt) !important;
    font-weight: bold;
}

nav .nav-item {
    cursor: pointer;
    padding: 4px 16px !important;
}
    
.card-header {
    padding: 0px 8px 0px 0px !important;
}

.card-body {
    padding: 6px !important;
}

.errorPageAnimation {
    animation: 1.5s ease-out errorPageAnimationKeyFrames 1;
}

@keyframes errorPageAnimationKeyFrames {
    0% {
        top: calc(100vh);
    }

    100% {
        top: 100px;
    }
}

.bs-popover-top .arrow:after {
    border-top-color: var(--black) !important;
}

.bs-popover-right .arrow:after {
    border-right-color: var(--black) !important;
}

.bs-popover-bottom .arrow:after {
    border-bottom-color: var(--black) !important;
}

.bs-popover-left .arrow:after {
    border-left-color: var(--black) !important;
}

.MuiTabs-flexContainer button,
.MuiTabs-flexContainer button span .MuiTab-wrapper .MuiTabs-flexContainer button span .MuiTouchRipple-root {
    padding: 0 !important;
    margin: 1px 0 1px 0 !important;
    max-height: 20px !important;
    min-height: 20px !important;
    max-width: 72px !important;
    min-width: 72px !important;
    text-transform: none;
    border: 2px solid var(--grey);
    border-radius: 12px 12px 0 0;
}

.MuiTabs-root {
    max-height: 25px !important;
    min-height: 25px !important;
}

.MuiRadio-colorSecondary.Mui-checked {
    color: var(--auto-purple) !important;
}

.MuiFormControlLabel-root .MuiCheckbox-root {
    margin: 0 12px auto;
    padding: 0;
    transition-duration: 0.3s;
    background-color: var(--white);
    color: $colorFg !important;
}

.MuiFormControlLabel-root .MuiCheckbox-root.Mui-checked {
    color: var(--auto-purple) !important;
}